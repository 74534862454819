export const simplificarTagImpostoJson = (obj) => {
    const result = {};

    for (const key in obj) {
        if (obj[key] && typeof obj[key] === 'object') {
            const subkeys = Object.keys(obj[key]);
            if (subkeys.length === 1 && typeof obj[key][subkeys[0]] === 'object') {
                result[key] = simplificarTagImpostoJson(obj[key][subkeys[0]]);
            } else {
                result[key] = simplificarTagImpostoJson(obj[key]);
            }
        } else {
            result[key] = obj[key];
        }
    }

    return result;
}