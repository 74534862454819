export const encontrarDescricaoProduto = async (json, message) => {
  const match = message.match(/\[nItem:(\d+)\]/);
  
  if (!match) {
      return ""; 
  }
  
  const itemNumber = parseInt(match[1], 10);
  
  const item = json.det.find(d => d.nItem === itemNumber);
  
  return item ? "=> " + item.prod.xProd : "";
}

