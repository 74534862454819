import React, { useEffect, useReducer, useState } from "react";
import {
  Button,
  Container,
  DialogContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery
} from "@mui/material";

import "./style.css"
import MainHeaderFx from "../../components/MainHeaderFx/index.js";
import MainContainer from "../../components/MainContainer/index.js";
import { i18n } from "../../translate/i18n.js";
import { xmlParaJson } from "../../utils/funcoes/conversoes/xmlParaJson.js";
import { buscarEmpresa } from "../../utils/funcoes/buscas/buscarEmpresa.js";
import api from "../../services/api.js";
import { formatarCnpjCpf } from "../../utils/formatacao/cnpjCpf.js";
import LoadingModal from "../../components/Modal/LoadingModal/index.js";
import { geraJsonNota } from "../../utils/funcoes/geraJsonNota/geraJsonNota.js";
import DialogModal from "../../components/DialogModal/index.js";
import { simplificarTagImpostoJson } from "../../utils/funcoes/conversoes/simplificarTagImpostoJson.js";
import { removerFieldJson } from "../../utils/funcoes/conversoes/removerFieldJson.js";
import { useNavigate } from "react-router-dom";

const DevolucaoCompra = () => {

  const isSmallScreen = useMediaQuery("(max-width:825px)");
  const navigate = useNavigate();
  const [jsonData, setJsonData] = useState(null);
  const [dadosNota, setDadosNota] = useState(null);
  const [empresa, setEmpresa] = useState(null);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [dialogModal, setDialogModal] = useState({open: false, keyword: "", title: "", description: "", buttons: [], result: ""});

  useEffect(() => {    
    const fetchEmpresa = async () => {
      const dadosEmpresa = await buscarEmpresa();
      setEmpresa(dadosEmpresa);
    }
    fetchEmpresa();
  }, []);


  useEffect(() => {    
    if (jsonData) {
      try {
        if (empresa.cnpj !== jsonData.nfeProc.NFe.infNFe.dest.CNPJ) {
          setDialogModal({...dialogModal, keyword: "info", 
                          open: true, 
                          title: i18n.t("devolucaoCompra.msgErroTitulo"),
                          description: i18n.t("devolucaoCompra.msgCnpjInvalido").replace("@empresa", formatarCnpjCpf(empresa.cnpj)).replace("@destinatario", formatarCnpjCpf(jsonData.nfeProc.NFe.infNFe.dest.CNPJ)), 
                          buttons: ["OK"]}); 
        } else  {

          const data = removerFieldJson(jsonData, "vTotTrib");
          setDadosNota({
            chave: data.nfeProc.protNFe.infProt.chNFe,
            nNF: data.nfeProc.NFe.infNFe.ide.nNF,
            dhEmi: data.nfeProc.NFe.infNFe.ide.dhEmi,
            ide: {
              finNFe: "1",
              indFinal: "1",
              mod: "55",
              tpNF: "1",
              idDest: "1",
              tpImp: "1",
              tpEmis: "1",
              indPres: "1",
            },
            dest: {...data.nfeProc.NFe.infNFe.emit,
              enderEmit: undefined,
              enderDest: data.nfeProc.NFe.infNFe.emit.enderEmit
            },
            emit: {...data.nfeProc.NFe.infNFe.dest,
              enderDest: undefined,
              enderEmit: data.nfeProc.NFe.infNFe.dest.enderDest
            },
            det: processarDet(data.nfeProc.NFe.infNFe.det),
            pag: "0", 
            pagamento: [data.nfeProc.NFe.infNFe.pag.detPag],
            transp: data.nfeProc.NFe.infNFe.transp,
            icmsTot: 
            {
              valorProduto: data.nfeProc.NFe.infNFe.total.ICMSTot.vProd,
              valorDesconto: data.nfeProc.NFe.infNFe.total.ICMSTot.vDesc,
              totalNf: data.nfeProc.NFe.infNFe.total.ICMSTot.vNF,
              totalTributos: "0",
            },
            infAdic: data.nfeProc.NFe.infNFe.infAdic,
          });   
        }   
      } catch (error) {
        setDialogModal({...dialogModal, keyword: "info", 
                        open: true, 
                        title: i18n.t("devolucaoCompra.msgXmlInvalido"), 
                        description: `${error?.message}`, 
                        buttons: ["OK"]}); 
      }
      setLoadingScreen(false);	 
    }
  }, [jsonData]);


  const processarDet = (inputArray) => {
    return inputArray.map(item => {
        if (item.imposto) {
            item.imposto = simplificarTagImpostoJson(item.imposto);
        }
        return item;
    });
}

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    if (file.type !== "text/xml") {
      setDialogModal({...dialogModal, keyword: "info", 
                      open: true, 
                      title: i18n.t("devolucaoCompra.msgErroTitulo"), 
                      description: i18n.t("devolucaoCompra.msgArquivoInvalido"), 
                      buttons: ["OK"]}); 
    } else {
      reader.onload = (e) => {
        setLoadingScreen(true);	
        const json = xmlParaJson(e.target.result);
        setJsonData(json);
      };
  
      reader.readAsText(file);
    }
    
  };

  const handleCloseLoadingModal = () => {    
		setLoadingScreen(false);
	}

  const handleAcessarNotasGeradas = () => {    
		navigate("/Notas");
	}

  const handleCloseDialogModal = (result) => {
		const keyword = dialogModal.keyword;
		setDialogModal({...dialogModal, keyword: "", open: false, result: result});
		
    switch (keyword) {
      case "notaGeradaSucesso": {
        handleAcessarNotasGeradas();
        break;
      }
    }
	};

  const handleCancelar = () => {    
		setDadosNota(null);
	}

  const handleGerarNota = async () => {
    try {
			setLoadingScreen(true);	

      const buscarNota = dadosNota;

			const jsonNota = await geraJsonNota(buscarNota, 'DEVOLVERCOMPRA')

			if (jsonNota.status === 200) {
				const envio = await api.post(`/v1/notas/`, jsonNota.nota);
				
				if (envio.data.cStat === '100') {
          handleCancelar();
					setDialogModal({...dialogModal, keyword: "notaGeradaSucesso", 
                  open: true, 
                  title: i18n.t("devolucaoCompra.msgGeracaoSucesso"), 
                  description: `${envio.data.mensagem}`, 
                  buttons: ["OK"]});
				} else {                        
					setDialogModal({...dialogModal, keyword: "info", 
									open: true, 
									title: i18n.t("devolucaoCompra.msgGeracaoFracasso"), 
									description: `${envio.data.mensagem}`, 
									buttons: ["OK"]});
				} 
			} 
			else {
				setDialogModal({...dialogModal, keyword: "info", 
								open: true, 
								title: i18n.t("devolucaoCompra.msgGeracaoFracasso"), 
								description: `${jsonNota?.message}`, 
								buttons: ["OK"]});
			}
		} catch (error) { 			
			const errorMsg = error.response?.data?.message || error.response.data.error;       
			setDialogModal({...dialogModal, keyword: "info", 
							open: true, 
							title: i18n.t("devolucaoCompra.msgGeracaoFracasso"), 
							description: `${errorMsg}`, 
							buttons: ["OK"]});
		}
		setLoadingScreen(false);
  }

  return (
    <>
    <DialogModal
				title={dialogModal.title}
				open={dialogModal.open}
				onClose={handleCloseDialogModal}  
				description={dialogModal.description}   
				buttons={dialogModal.buttons}      
				></DialogModal>
    <LoadingModal
				open={loadingScreen}
				onClose={handleCloseLoadingModal}/>  
    <div className="pageRoot">
      <MainHeaderFx
        title={i18n.t("devolucaoCompra.nome")}
        leftContainerType={"page"}
        rightContainerType={"hidden"}
        backParam={"/"}
        useHandleModal={false}
      />

      <MainContainer>  

        <div>
          <br/>
          {!dadosNota && <div style={{paddingBottom: "30px"}}>
            <strong style={{paddingLeft: "10px", paddingRight: "15px" }}>{i18n.t("devolucaoCompra.selecionarArquivo")}<span>{": "}</span></strong>
            <input type="file" onChange={handleFileSelect} />
          </div>} 
          {dadosNota && 
          <div style={{padding: "10px", paddingTop: "5px"}}>
            <div style={{backgroundColor: "white", fontSize: isSmallScreen ? "10px" : "", paddingLeft: "30px", paddingTop: "5px", paddingBottom: "10px"}}>
              <pre>
                <br/>
                <table width={"100%"}><th align={"center"}><strong>{i18n.t("devolucaoCompra.nota")}</strong></th></table><br/>

                <strong>{i18n.t("devolucaoCompra.chave")}</strong><span>{": "}</span><br/>
                {<span>{dadosNota.chave}</span>}<br/><br/>

                <strong>{i18n.t("devolucaoCompra.numero")}</strong><span>{": "}</span><br/>
                {<span>{dadosNota.nNF}</span>}<br/><br/>

                <strong>{i18n.t("devolucaoCompra.dataEmissao")}</strong><span>{": "}</span><br/>
                {<span>{new Date(dadosNota.dhEmi).toLocaleString("pt-BR", { dateStyle: "short", timeStyle: "medium" })}</span>}<br/><br/>

                <strong>{i18n.t("devolucaoCompra.emitente")}</strong><span>{": "}</span><br/>
                {<span>{formatarCnpjCpf(dadosNota.dest.CNPJ)}</span>}<span>{" "}</span>{<span>{formatarCnpjCpf(dadosNota.dest.xNome)}</span>}

                <br/><br/>
                <DialogContent style={{backgroundColor: "white", fontSize: isSmallScreen ? "8px" : "", marginLeft: "-30px"}}>
                  <div style={{border: "solid 1px black", padding: isSmallScreen ? "5px" : "30px", paddingTop: "5px"}}>
                  
                    <table width={"100%"}><th align={"center"}><span>{i18n.t("devolucaoCompra.itens")}</span></th></table>
                    <table width={"100%"}>
                    <tr>
                      <th align={"left"}>#</th>
                      <th align={"center"}>{i18n.t("devolucaoCompra.codigo")}</th>
                      <th align={"left"}>{isSmallScreen ? i18n.t("devolucaoCompra.descricaoSmall") : i18n.t("devolucaoCompra.descricao")}</th>
                      <th align={"right"}>{isSmallScreen ? i18n.t("devolucaoCompra.valorUnitarioSmall") : i18n.t("devolucaoCompra.valorUnitario")}</th>
                      <th align={"right"}>{isSmallScreen ? i18n.t("devolucaoCompra.quantidadeSmall") : i18n.t("devolucaoCompra.quantidade")}</th>
                      <th align={"right"}>{isSmallScreen ? i18n.t("devolucaoCompra.valorTotalItemSmall") : i18n.t("devolucaoCompra.valorTotalItem")}</th>
                    </tr>
                    {dadosNota?.det.map((array, index) => (
                      <tr>
                        <td>{index+1}</td>
                        <td align={"center"}>{array.prod.cProd}</td>
                        <td align={"left"}>{array.prod.xProd}</td>
                        <td align={"right"}>{parseFloat(array.prod.vUnCom).toFixed(2).replace(".", ",")}</td>
                        <td align={"right"}>{array.prod.qCom}</td> 
                        <td align={"right"}>{parseFloat(array.prod.vProd).toFixed(2).replace(".", ",")}</td>
                      </tr>))}          
                      <tr>
                        <td align={"right"} colSpan={"5"}><strong>{i18n.t("devolucaoCompra.valorTotal")}</strong></td>
                        <td align={"right"} colSpan={"6"}><strong>{parseFloat(dadosNota.icmsTot.totalNf).toFixed(2).replace(".", ",")}</strong></td>
                      </tr>
                    </table>
                    <br/>	
                  </div>
                </DialogContent>
              </pre>   
            </div>
              <Button
              onClick={handleGerarNota}
              type="submit"
              color="primary"
              variant="contained"
              style={{marginTop: "10px"}}
              >{i18n.t("devolucaoCompra.btnGerarDevolucao")}</Button>
              <Button
              onClick={handleCancelar}
              type="submit"
              color="inherit"
              variant="contained"
              style={{marginLeft: "15px", marginTop: "10px"}}
              >{i18n.t("devolucaoCompra.btnCancelar")}</Button>
          </div>
          }
          
        </div>
      </MainContainer>
    </div>
    </>
  );
};

export default DevolucaoCompra;
