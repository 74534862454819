import { gerarCfoDevolucao } from "./gerarCfoDevolucao";

export const atualizarCFOPDevolucao = (det, ide, dest) => {

    det.forEach(item => {
        if (item.prod && item.prod.CFOP && item.imposto && item.imposto.ICMS && item.imposto.ICMS.CSOSN) {
            item.prod.CFOP = gerarCfoDevolucao(item.prod.CFOP, ide.operacao, ide.idDest); 
        }
    });

    return det;
}