const messages = {
  pt: {
    translations: {
      titleSystem: {
        title: "Nota Certa",
        subTitle: "NFCe",
      },
      languages: {
        language1: "Português",
        language2: "English",
      },
      page404: {
        message: "Parece que você se perdeu, clique em uma das opções do lado esquerdo para voltar à página!",
      },

      menuRedirectorContext: {
        settingsDialogModalInfo: {
          title: "Configurações não salvas",
          description: "Deseja salvar as alterações?",

          buttons: {
            save: "Salvar",
            cancel: "Cancelar",
          },
        },
      },

      drawerMenu: {
        title: "Nota Certa",
        items: {
          mainItems: {
            emission: "Emitir Nota",
            registrations: "Cadastros",
            items: "Notas Emitidas",
            reports: "Relatórios",
            settings: "Configurações",
            lancamentos: "Lançamentos",
            logout: "Sair",
          },
          subItems: {
            companies: "Empresa",
            users: "Usuários",
            products: "Produtos",
            receiptsReport: "Notas",
            customers: "Clientes",
            productReport: "Produtos",
            devolucaoDeCompra: "Devolução de Compra",
          },
        },
      },
      main:{
        expiredCertificate: "O certificado vence em",
        days: "dias"
      },
      ncmSearchModal: {
        title: "Pesquisar NCM",
        ncm: "NCM",
      },
      clienteSearchModal: {
        title: "Selecionar cliente",
        cliente: "Procurar por nome ou CPF/CNPJ",
        notFound: "Nenhum cliente encontrado",
        clienteConfirma: "Deseja selecionar o cliente:"
      },
      search: {
        search: "Pesquisar",
      },
      genericPage: {
        headerTitle: "Cabeçalho do Título",
        titleButton: "Botão do Título",
      },
      login: {
        message: "Vamos começar!",
        login: "Login",
        form: {
          email: "E-mail",
          password: "Senha",
          esqueciSenha: "Esqueci a minha senha",
        },
        buttons: {
          submit: "Entrar",
        },
      },
      button: {
        add: "Adicionar",
        exit: "Sair",
        edit: "Editar",
        delete: "Excluir",
        save: "Salvar",
        cancel: "Cancelar",
        enter: "Entrar",
        close: "Fechar",
        replace: "Substituir",
      },
      toasts: {
        deleted: "Excluído com sucesso!",
        saved: "Gravado com sucesso!",
        embalagemError: "Preencher todos os campos!",

        exceptions: {
          unauthorizedUserCreationAndUpdate: "Apenas administradores podem criar e alterar outros usuários!",
        },
      },
      validation: {
        error: {},
        warning: {
          tooLong: "Muito longo!",
          tooShort: "Muito curto!",
          required: "Obrigatório",
          emailInvalid: "E-mail inválido",
        },
      },
      devolucaoCompra: {
        nome: "Devolução de Compra",
        selecionarArquivo: "XML de ENTRADA",
        nota: "DADOS DA NOTA",
        chave: "Chave",
        numero: "Número",
        dataEmissao: "Data de Emissão",
        emitente: "Emitente",
        itens: "ITENS",
        codigo: "Cód.",

        descricao: "Descrição",
        valorUnitario: "Vlr. Unitário",
        valorTotalItem: "Vlr. Total",
        quantidade: "Qtd.",

        descricaoSmall: "Desc.",
        valorUnitarioSmall: "Unit.",
        valorTotalItemSmall: "Tot.",
        quantidadeSmall: "Qtd.",

        valorTotal: "TOTAL",

        btnGerarDevolucao: "Gerar Devolução",
        btnCancelar: "Cancelar",
        msgCnpjInvalido: "O destinatário (@destinatario) não condiz com a empresa do Nota Certa (@empresa).",
        msgErroTitulo: "Não foi possível continuar!",
        msgXmlInvalido: "XML inválido!",
        msgArquivoInvalido: "Apenas arquivos XMLs podem ser lidos!",
        msgGeracaoSucesso: "Nota de Devolução gerada com sucesso!",
        msgGeracaoFracasso: "Não foi possível gerar a Nota de Devolução!",
      },
      empresa: {
        title: "Empresa",
        pluralTitle: "Empresa",

        fields: {
          razaoSocial: "Razão Social",
          nomeFantasia: "Nome Fantasia",
          cnpj: "CNPJ",
          inscricaoEstadual: "Inscrição Estadual",

          endereco: {
            title: "Endereço",
            cep: "CEP",
            logradouro: "Endereço",
            numero: "Número",
            complemento: "Complemento",
            bairro: "Bairro",
            municipio: "Município",
            uf: "UF",
            pais: "País",
          },
          tributacao: {
            title: "Regime tributário",
            apuracaoImposto: "Tipo apuração", 
            cnaePrincipal: "CNAE",
          },
        },
      },
      produto: {
        title: "Produto",
        pluralTitle: "Produtos",

        fields: {
          nome: "Nome",
          statusAtivo: "Ativo",
          statusInativo: "Inativo",
          embalagem: "Embalagem",
          preco: "Preço",
        },

        toasts: {
          requiredFieldsAreBlank: "Alguns campos obrigatórios não foram preenchidos!",
        },
      },

      cliente: {
        title: "Cliente",
        pluralTitle: "Clientes",
        table: {
          nome: "Nome / Razão Social",
          inscricao: "CPF/CNPJ"
        },
        fields: {
          nome: "Nome",
          inscricao: "CPF/CNPJ",
          inscricaoEstadual: "I.E.",
          inscricaoEstadualIndicador: "Indicador I.E.",
          logradouro: "Endereço",
          numero: "Número",
          complemento: "Complemento",
          bairro: "Bairro",
          cep: "CEP",
          telefone: "Telefone",
          municipio: "Município",
          uf: "UF",
          email: "Email",
        }
      },

      vendas: {
        title: "Vendas",
        table: {
          status: "Status",
          type: "Tipo",
          modelo: "Modelo",
          emissao: "Emissão",
          numero: "Número",
          numeroSmartphone: "Mod./Nº",
          valor: "Valor",
          chave: "Chave",

          types: {
            normal: "Venda",
            supplement: "Complementar",
            adjustment: "Ajuste",
            return: "Devolução",
          },

          acoesLista: {
            visualizar: "Visualizar",
            visualizarErro: "Visualizar Erro",
            download: "Download",
            imprimir: "Imprimir",
            copiarChave: "Copiar Chave",
            compartilhar: "Compartilhar",
            cancelarNota: "Cancelar Nota",
            corrigirReenviar: "Corrigir / Reenviar",
            inutilizar: "Inutilizar",
            gerarNfeDaNfce: "Gerar NF-e a partir da NFC-e",
            gerarDevolucao: "Gerar NF-e de devolução"
          }
        }
      },

      receiptsReport: {
        title: "Relatório de Notas",

        filters: {
          date: "Data",
          generateReportButton: "Gerar Relatório",
        },

        validations: {
          tooManyRequisitions: "Um relatório já está sendo gerado. Por favor, espere ele ser completado!",
          invalidDate: "Por favor, informe uma data válida!",
          selectedDateAfterCurrentDate: "Data selecionada não pode ser maior do que a atual!",
        },

        toasts: {
          emptyReport: "Não há notas realizadas no período selecionado!",
        },

        document: {
          table: {
            header: {
              date: "Data",
              model: "Modelo",
              number: "Número",
              status: "Status",
              total: "Total (R$)",
            },

            info: {
              title: "REGISTRO DE NOTAS",
              company: "Empresa:",
              stateRegistration: "Insc. Est:",
              cnpj: "CNPJ:",
              period: "Período:",
              total: "Total:",
              authorized : "Autorizadas:",
              cancelled: "Canceladas:",
              sales: "Vendas",
              returns: "Devoluções de Vendas",
            },
          },
        },
      },

      productsReport:{
        title: "Relatório de Produtos",

        filters: {
          generateReportButton: "Gerar Relatório"
        },

        validations: {
          tooManyRequisitions: "Um relatório já está sendo gerado. Por favor, espere ele ser completado!"   
        },

        toast: {
          emptyReport: "Não há produtos cadastrados!",
        },

        document :{
          table: {
            header: {
              name: "Nome",
              value: "Valor (R$)",
              status: "Status",
              package: "Embalagem",
              csosn: "CSOSN",
              ncm: "NCM",
              cest: "CEST",
              cstPisCofins: "CST Pis/Cofins"
            },
            info: {
              title: "REGISTRO DE PRODUTOS",
              company: "Empresa:",
              stateRegistration: "Insc. Est:",
              cnpj: "CNPJ:",
              period: "Período:", 
            },
          },
        },
      },

      empresaConfig: {
        geral: {
          title: "Geral",
          ambiente: "Ambiente",
          emitirNfce: "Emitir NFC-e",
          emitirNfe: "Emitir NF-e",
        },
        ultimoNumero: "Número",
        serie: "Série",
        nfce:{
          title: "NFC-e", 
          informarCpf: "Informar CPF",
          csc: "ID Código",
          codigoCsc: "Cód Segurança",
        },
        nfe:{
          title: "NF-e", 
        },
        certificado: {
          title: "Certificado",
          hashSenha: "Senha",
          certificado: "Certificado",
          dataValidade: "Validade",
          expired: "Expirado",
        },
        tributacaoPadrao: {
          title: "Tributação Padrão",
          operacao: "Operação",
          cstCsosnIcms: " CSOSN",
          cstPisCofins: "Pis Cofins",
          ncm: "NCM",
          cest: "CEST",
          padrao: "Usa tributação padrão",
        },
        validations: {
          csosnInvalid: "CSOSN Inválido!",
          nenhumTipo: "É necessário marcar pelo menos 1 tipo de emissão!",
          nfce: {
            invalid: "Preencha os campos de NFC-e corretamente!",
            numero: "NFC-e: O último número deve ser pelo menos zero!",
          },
          nfe: {
            invalid: "Preencha os campos de NF-e corretamente!",
            numero: "NF-e: O último número deve ser pelo menos zero!",
          },
        }
      },

      emissao: {
        titleNFCe: "NFC-e",
        titleNFe: "NF-e",
        titleBoth: "Emitir Nota",
      },

      user: {
        title: "Usuário", 
        pluralTitle: "Usuários",
        field: {
          name: "Nome",
          password: "Senha",
          email: "E-mail",
          profile: "Perfil",
          admin: "Administrador",
          user: "Usuário",
        },
      },
    },
  },
};

export { messages };
