import React, { useState, useEffect, useReducer, useMemo } from "react";

import {
  Container,
  Button,
  Dialog,
  useMediaQuery,
  Divider,  
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";

import "./style.css";
import api from "../../../services/api";
import { i18n } from "../../../translate/i18n";

import MainHeaderFX from "../../MainHeaderFx";
import CardSkeleton from "../../CardSkeleton";
import InformarQtdPreco from "../../InformarQtdPreco";
import EmissaoPagamentoModal from "../EmissaoPagamentoModal";
import { formatarValor } from "../../../utils/formatacao/formatarValor";

import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import ImpressaoVisualizacaoDocumento from "../../ImpressaoVisualizacaoDocumento";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import toastError from "../../../errors/toastError";
import EditIcon from '@mui/icons-material/Edit';

const reducer = (state, action) => {      
  if (action.type === "LOAD_PRODUTO") {
    const produtos = action.payload;
    const newProduto = [];

    produtos.forEach((produto) => {
      const produtoIndex = state.findIndex((u) => u.id === produto.id);
      if (produtoIndex !== -1) { state[produtoIndex] = produto; }
      else { newProduto.push(produto); }
    });   

    return [...state, ...newProduto];
  }

  if (action.type === "RESET") { return []; }
};

const EmissaoModal = ({ open, onClose, emissaoId, urlPage }) => {
  let modelo = "65";
  let tipoEmissao = "1";

	const initialState = {
    ide: {      
      finNFe: "1",
      indFinal: "1",
      mod: modelo,
      tpNF: "1",
      idDest: "1",
      tpImp: modelo === "65" ? "4" : "1",
      tpEmis: tipoEmissao,
      indPres: modelo === "65" && "1",
    },

    icmsTot: {
      valorProduto: 0,
      valorDesconto: 0,
      totalNf: 0,
      totalTributos: 0,      
    },
    
    dataEmissao: new Date().toISOString().split('T')[0],
    dataSaida: new Date().toISOString().split('T')[0],    
    item: [], 
    
    transp: {
      modFrete: "9"
    },

    infAdic: {
      infCpl: "",
    }
  }; 

  const [arrayProdutos, dispatch] = useReducer(reducer, []);  
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [subTotal, setSubTotal] = useState(0);  
  const [loading, setLoading] = useState(false);
  const [produtoId, setProdutoId] = useState(0);  
  const [produtoSacolaId, setProdutoSacolaId] = useState(0);  
  const [produtoSacolaValor, setProdutoSacolaValor] = useState(0); 
  const [hasMore, setHasMore] = useState(false);  
  const [isScroll, setIsScroll] = useState(false);
  const [emissao, setEmissao] = useState(initialState);
  const [operacao, setOperacao] = useState("QUANTIDADE");
  const [showPagamento, setShowPagamento] = useState(false);
  const [contadorCliques, setContadorCliques] = useState({});
  
  const quantidadeProdutos = useMemo(
    () => {
      return Object.values(contadorCliques).reduce((accumulator, quantidade) => accumulator + quantidade, 0)
    },
    [contadorCliques]
  );

  const [notaRetorno, setNotaRetorno] = useState(null);
  const [quantidadeProduto, setQuantidadeProduto] = useState(0);
  const [descricaoOpcaoSelecionada, setDescricaoOpcaoSelecionada] = useState("");  
  const [openEmissaoPagamentoModal, setOpenEmissaoPagamentoModal] = useState(false);
  const [openModalInformaQuantidade, setOpenModalInformaQuantidade] = useState(false);
  const [openModalInformaPrecoSacola, setOpenModalInformaPrecoSacola] = useState(false);
  const [openModalImpressaoVisualizacao, setOpenModalImpressaoVisualizacao] = useState(false);
  const [especie, setEspecie] = useState("NFCE");
  const [tituloTela, setTituloTela] = useState(i18n.t("emissao.titleNFCe"));
  const [emissaoDupla, setEmissaoDupla] = useState(false);
  
  const isSmallScreen = useMediaQuery('(max-width:825px)');  

  useEffect(() => {
    const contadorInicial = {};
    arrayProdutos.forEach(produto => {
      contadorInicial[produto.id] = 0;
    });
    setContadorCliques(contadorInicial);
  }, []);  

  useEffect(() => {
    (async () => {
      if (open) {
      try {
        const { data: dataEmpresa } = await api.get("/v1/empresas");

        const { data } = await api.get(`/v1/empresas-configuracoes-payload/${dataEmpresa.empresas[0].id}`);

        setEspecie(data.configuracao.emitirNfce ? "NFCE" : "NFE");
        modelo = data.configuracao.emitirNfce ? "65" : "55";

        const emiteAmbos = data.configuracao.emitirNfce && data.configuracao.emitirNfe;
        setEmissaoDupla(emiteAmbos);
        setTituloTela(emiteAmbos ? i18n.t("emissao.titleBoth") : (data.configuracao.emitirNfe ? i18n.t("emissao.titleNFe") : i18n.t("emissao.titleNFCe")));
      } catch (error) {
        toastError(error);
      }
    }})();
  }, [open]);

  useEffect(() => {
    if (open) {
      window.history.replaceState({}, null, "/")
    }
  }, [open]);  

  useEffect(() => {  
    setLoading(true);   
    const delayDebounceFN = setTimeout(() => {      
      const fetchProdutos = async () => {
        const { data } = await api.get("/v1/produtos",  { params: {size: 25, search, page, filter: "status:ativo"}});  
        
        setIsScroll(true);
        dispatch({ type: "LOAD_PRODUTO", payload: data.produtos}); 
        setLoading(false);       
        setHasMore(data.hasMore);        
      };
      fetchProdutos();
    }, 1000);
    return () => clearTimeout(delayDebounceFN);
  }, [search, page]);

  useEffect(() => {    
    dispatch({ type: "RESET" });
    setPage(1);    
  }, [search]);

  const calcularTotalNf = (emissao) => {
    let total = 0;
    emissao.item.forEach(produto => {
      total += produto.valorProduto;
    });

    total = parseFloat(total.toFixed(2));

    setSubTotal(total);
    return total;
  };

  const atualizarTotalNf = () => {
    setEmissao(prevState => {
      const total = calcularTotalNf(prevState);  
      return {
        ...prevState,
        icmsTot: {
          ...prevState.icmsTot,
          totalNf: total
        }
      };
    });    
  };

  const handleProdutoSacolaUpdate = (produtoIdValue, valor) => {
    const produtoExistente = emissao.item.find(item => item.produtoId === produtoIdValue);
    
    if (produtoExistente) {
  
      const novoValorTotal = produtoExistente.quantidadeComercial * valor;

      const produtosAtualizados = emissao.item.filter(item => {
        return item.produtoId !== produtoIdValue || valor !== 0;
      }).map(item => {
          if (item.produtoId === produtoIdValue) {
              return {
                  ...item,
                  preco: valor,
                  valorProduto: novoValorTotal,
                  produtoId: produtoIdValue,
                  valorUnitarioComercial: parseFloat(valor),
                  valorDesconto: 0,
                  valorAlterado: true
              };
          }
          return item;
      });

      setEmissao(prevState => ({
        ...prevState,
        item: produtosAtualizados,
      }));

      atualizarTotalNf();
  }
}

  const handleProdutoClick = (produtoId, operacao, quantidadeSelecionada) => {    
    setContadorCliques(prevState => {
      const contadorAtualizado = { ...prevState };          

      if (quantidadeSelecionada > 0 && contadorAtualizado[produtoId] > 0) {        
        contadorAtualizado[produtoId] = quantidadeSelecionada;
      } else {
        if (operacao === "ADICIONAR") {
          contadorAtualizado[produtoId] = (contadorAtualizado[produtoId] || 0) + 1;
        } else {
          contadorAtualizado[produtoId] = (contadorAtualizado[produtoId] || 0) - 1;
        }
      }

      return contadorAtualizado;
    });    
    
    const produtoSelecionado = arrayProdutos.find(produtos => produtos.id === produtoId);
    const produtoExistente = emissao.item.find(item => item.produtoId === produtoId);
    
    if (produtoExistente) {
      let novaQuantidade = 0;

      if (quantidadeSelecionada > 0) {
        novaQuantidade = quantidadeSelecionada;
      } else {
        if (operacao === "ADICIONAR") { 
          novaQuantidade = produtoExistente.quantidadeComercial + 1;
        } else {
          novaQuantidade = produtoExistente.quantidadeComercial - 1;
        }
      }

      const novoValorTotal = novaQuantidade * produtoExistente.preco;

      const produtosAtualizados = emissao.item.filter(item => {
        return item.produtoId !== produtoId || novaQuantidade !== 0;
      }).map(item => {
          if (item.produtoId === produtoId) {
              return {
                  ...item,
                  quantidadeComercial: novaQuantidade,
                  valorProduto: novoValorTotal,
                  produtoId: produtoId,
                  valorUnitarioComercial: parseFloat(produtoExistente.preco),
                  valorDesconto: 0,
              };
          }
          return item;
      });

      setEmissao(prevState => ({
        ...prevState,
        item: produtosAtualizados,
      }));
    } else {      
      const quantidadeComercial = 1; 

      const precoProduto = parseFloat(produtoSelecionado.preco) || 0; 
      const valorTotal = precoProduto * quantidadeComercial;
      
      const produtoAtualizado = {
        ...produtoSelecionado,
        quantidadeComercial: quantidadeComercial,
        valorProduto: valorTotal,
        produtoId: produtoId,
        valorUnitarioComercial: parseFloat(produtoSelecionado.preco),
        valorDesconto: 0,
      };
      
      setEmissao(prevState => ({
        ...prevState,
        item: [...prevState.item, produtoAtualizado],
      }));
    }
    
    atualizarTotalNf();
  };
    
  const handleCloseEmissaoModal = () => {    
		onClose();
		setEmissao(initialState);    
    setShowPagamento(false);
    setContadorCliques({});
    setSearch("");
    setPage(1)
	};

  const handleCloseEmissaoPagamentoModal = (limparModal, notaRetorno) => {
    setShowPagamento(previous => !previous)
    setOpenEmissaoPagamentoModal(false);   
    setShowPagamento(false);
    setNotaRetorno(notaRetorno);
    
    if (limparModal) {
      setEmissao(initialState);
      setSubTotal(0);  
      setContadorCliques({});
    } 

    if (notaRetorno?.situacao === "Autorizada") {
      setOpenModalImpressaoVisualizacao(true)
    }
      
  } 

  const handleClickPagamentos = () => {        
    setOpenEmissaoPagamentoModal(true);
  }

  const handleRegistros = () => {
    setShowPagamento(previous => !previous)
    setSearch("");
  }

  const handleOpenInformaQuantidade = (operacao, descricao, quantidade, produtoId) => {
    setOperacao(operacao)    
    setQuantidadeProduto(quantidade)
    setProdutoId(produtoId)
    setOpenModalInformaQuantidade(true)  
    setDescricaoOpcaoSelecionada("Informe a quantidade")          
  }  

  const handleOpenInformaPreco = (operacao, valor, produtoIdSacola, descricao) => {
    setOperacao(operacao)    
    setProdutoSacolaValor(valor)
    setProdutoSacolaId(produtoIdSacola)
    setOpenModalInformaPrecoSacola(true)  
    setDescricaoOpcaoSelecionada(descricao)          
  } 

  const handleCloseModalInformarPrecoSacola = () => {
    setDescricaoOpcaoSelecionada("");     
    setOpenModalInformaPrecoSacola(false)    
    setQuantidadeProduto(0);
    setProdutoId(0);
  }
  
  const handleCloseModalInformarQtdPreco = () => {
    setDescricaoOpcaoSelecionada("");     
    setOpenModalInformaQuantidade(false)    
    setQuantidadeProduto(0);
    setProdutoId(0);
  }

  const handleQtdPrecoSelecionado = (qtdPreco) => {    
    const novaQuantidade = parseFloat(qtdPreco)
    handleProdutoClick(produtoId, "NOVA-QUANTIDADE", novaQuantidade)              
  }; 

  const handlePrecoSacola = (valor) => {    
    const novoValor = parseFloat(valor)
    handleProdutoSacolaUpdate(produtoSacolaId, novoValor)              
  }; 

  const handleCloseModalImpressaoVisualizacao = () => {
    setOpenModalImpressaoVisualizacao(false)
  }

  const loadMore = () => {       
    if (isScroll) {      
      setPage((prevState) => prevState + 1);
      setIsScroll(false);
    }    
  };

  const handleScroll = (e) => {  
    if (!hasMore || loading) return;    
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    
    if (scrollHeight - (scrollTop + 1) < clientHeight) { 
      loadMore(); 
    }
  }; 

  const handleChangeEspecie = (event, newValue) => {
    setEspecie(newValue);
  };
 
  return (
    <>
      <EmissaoPagamentoModal
        open={openEmissaoPagamentoModal}
        onClose={handleCloseEmissaoPagamentoModal}
        especie={especie}
        json={emissao} 
        subTotal={subTotal}
      />
      
      <InformarQtdPreco
        open={openModalInformaQuantidade}
        title={descricaoOpcaoSelecionada}
        faltaPagar={quantidadeProduto}
        onClose={handleCloseModalInformarQtdPreco}
        onQtdPrecoSelecionado={handleQtdPrecoSelecionado}        
        operacao={operacao}
      /> 

      <InformarQtdPreco
        open={openModalInformaPrecoSacola}
        title={descricaoOpcaoSelecionada}
        faltaPagar={produtoSacolaValor}
        onClose={handleCloseModalInformarPrecoSacola}
        onQtdPrecoSelecionado={handlePrecoSacola}        
        operacao={operacao}
      /> 
      
      <ImpressaoVisualizacaoDocumento
        open={openModalImpressaoVisualizacao}
        onClose={handleCloseModalImpressaoVisualizacao}     
        nota={notaRetorno} 
      />

      <Dialog
        open={open}   
        close     
        fullWidth={true}
        fullScreen={isSmallScreen} 
        maxWidth={"true"}      
      >
        
        <MainHeaderFX 
          title={tituloTela}
          leftContainerType={"modal"}
          rightContainerType={"hidden"}   
          backModal={showPagamento}         
          handleCloseModal={!showPagamento ? handleCloseEmissaoModal : handleRegistros}     
        />

        {emissaoDupla && (
          <Box className="campoEspecie" sx={{ width: '100%' }}>
            <Tabs value={especie} onChange={handleChangeEspecie}>
              <Tab value="NFCE" label="NFC-e" />
              <Tab value="NFE" label="NF-e" />
            </Tabs>
          </Box>
        )}

        <Container class="main">
          {(!isSmallScreen || (isSmallScreen && !showPagamento)) && (
            <Container class="containerProducts" style={{ width: isSmallScreen ? "100%" : "60%" }}>
              {isSmallScreen && (
                <Container class="carrinho" style={{opacity: quantidadeProdutos > 0 ? 1 : 0}}>
                  <div class="verCarrinho">
                    <ShoppingCartIcon
                      style={{marginLeft: 15, marginRight: 5, fontSize: 28}}
                      onClick={quantidadeProdutos > 0 ? handleRegistros: undefined}
                    />                          

                    {quantidadeProdutos > 0 && (
                      <circle className="counterTotalProducts">{quantidadeProdutos}</circle>
                    )}
                  </div>

                  <div class="SubTotalCarrinho">
                    <div>SubTotal</div>
                    <div style={{fontSize: 16}}><b>{formatarValor(subTotal)}</b></div>
                  </div>                  
                </Container> 
              )} 

              <Container class="search"> 
                <div class="divSearch" style={{ textAlign: "center"}}>
                  <TextField 
                    variant="outlined"
                    margin="normal"         
                    fullWidth
                    size="small"                      
                    label={i18n.t("search.search")}                          
                    value={search}                    
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {search && (
                            <IconButton>
                              <CloseOutlinedIcon onClick={() => setSearch("")} />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <Container class="containerTitleProduto">
                  <p><b><center>Produtos</center></b></p>                      
                </Container>
              </Container>                 

              <Container class="products" onScroll={handleScroll}>
                {arrayProdutos.map(produto => {
                  const nomeProduto = produto.nome.length > 33 ? produto.nome.substring(0, 33) + '...' : produto.nome;
                  return (
                    <div class="productsInformations">    
                      <Container class="productsIcon">
                        <img 
                          width={"57px"} 
                          alt="" 
                          src={produto.urlFoto ? produto.urlFoto : `./imagens/defaultProduct.png`} 
                        />
                      </Container>

                      <Container class="productsName">
                        <span>{nomeProduto}</span>
                      </Container>

                      {contadorCliques[produto.id] > 0
                        ? (
                          <Container class="productsQtdeMaior">
                            <div class="divExcluirItem">
                              <IconButton>
                                {
                                  contadorCliques[produto.id] > 1 ? (
                                    <IndeterminateCheckBoxIcon onClick={() => handleProdutoClick(produto.id, "EXCLUIR")}/>
                                  ) : (
                                    <DeleteIcon onClick={() => handleProdutoClick(produto.id, "EXCLUIR")}/>
                                  )
                                }
                              </IconButton> 
                            </div>

                            <div class="divQtdeItem"
                              onClick={() => (handleOpenInformaQuantidade("QUANTIDADE", produto.nome, contadorCliques[produto.id], produto.id))}
                            > 
                              {contadorCliques[produto.id]}                                       
                            </div>
                          
                            <div class="divAddItem"> 
                              <IconButton>
                                <AddBoxIcon onClick={() => handleProdutoClick(produto.id, "ADICIONAR")}/>                                      
                              </IconButton>                             
                            </div>
                          </Container>                      
                        ) : (
                          <Container class="productsQtde" onClick={() => handleProdutoClick(produto.id, "ADICIONAR")}>
                            <div>Adicionar</div>
                          </Container>
                        )
                      }

                      <Container class="productsPreco">
                        <span>{formatarValor(produto.preco)}</span>
                      </Container>
                    </div>                      
                  )
                })}  
                {loading && <CardSkeleton columns={isSmallScreen ? 8: 25} />}
              </Container>                

              <Container class="buttonPagamentoProducts">
                {isSmallScreen && (
                  <div class="divResumo"> 
                    <Button 
                      color="primary"                  
                      variant="contained" 
                      disabled={!Object.values(contadorCliques).some(valor => valor > 0)}
                      style={{width: "95%", height: 55, borderRadius: 10}}                        
                      onClick={handleClickPagamentos} 
                    >
                      Forma de Pagamento
                    </Button>
                  </div>
                )}  
              </Container>  
            </Container>
          )}

          {(!isSmallScreen || (isSmallScreen && showPagamento)) && (
            <Container class="containerCfe" style={{ width: isSmallScreen ? "100%" : "40%" }}>
              <Container class="cfe">
                {emissao.item.map(produto => (
                  <div key={produto.id}>
                    <Container class="itensDescription">
                      <p>{produto.nome.length > 35 ? produto.nome.substring(0, 35) + '...' : produto.nome}</p>                         
                      <p><b>{formatarValor(produto.valorProduto)}</b></p>
                    </Container>

                  <Container class="itensTotais">
                    <p>{produto.quantidadeComercial} {produto.embalagem.unidade} x 
                      <Button 
                        style={{height: "25px", fontSize: "18px", marginTop: "-6px", marginLeft: "-3px"}}
                        onClick={() => (handleOpenInformaPreco("VALOR", produto.preco, produto.id, produto.nome.substring(0, 35)))}>
                         {  produto.valorAlterado ? <strong>{formatarValor(produto.preco)}</strong> : formatarValor(produto.preco) }
                          <EditIcon className={produto.valorAlterado ? "" : "edit-icon"} />
                      </Button> 
                    </p> 
                    
                  </Container>
                  <Divider />
                  
                </div>
              ))}
            </Container>            

              <Divider/>

              <Container class="pagamentoCfe">
                <Container class="pagamentoTitle flex">Informações dos Totais</Container> 
                
                <Container class="containerInformationPag"> 
                  <p>Total</p>
                  <p><b>{formatarValor(emissao.icmsTot.totalNf)}</b></p> 
                </Container> 

                <div class="buttonPagamento"> 
                  <Button 
                    color="primary"                  
                    variant="contained" 
                    style={{width: "95%", height: 55, borderRadius: 10}}
                    disabled={quantidadeProdutos === 0}
                    onClick={handleClickPagamentos}                     
                  >
                    Forma de Pagamento
                  </Button>
                </div>
              </Container>
            </Container> 
          )}
        </Container>                 
      </Dialog>
    </>
    
  );
};

export default EmissaoModal;