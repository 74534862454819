import api from "../../../services/api";

export const buscarDescricaoProduto = async (id, message) => {
  const match = message.match(/\[nItem:(\d+)\]/);
  
  if (!match) {
      return ""; 
  }

  const json = await api.get(`/v1/notas/${id}`); 
  
  const itemNumber = parseInt(match[1], 10);
  
  const item = json.data.det.find(d => d.nItem === itemNumber);
  
  return item ? "=> " + item.prod.xProd : "";
}

