import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  AssessmentOutlined,
  CategoryOutlined,
  ContactsOutlined,
  ContentPasteOutlined,
  ExpandLess,
  ExpandMore,
  ListAltOutlined,
  LogoutOutlined,
  NoteAddOutlined,
  NoteAltOutlined,
  PersonOutlineOutlined,
  RequestQuoteOutlined,
  SettingsOutlined,
  StoreOutlined,
} from "@mui/icons-material";

import { AuthContext } from "../context/Auth/AuthContext";
import { MenuRedirectorContext } from "../context/MenuRedirector/MenuRedirectorContext";
import { i18n } from "../translate/i18n";
import isUserAdmin from "../utils/funcoes/validacoes/isUserAdmin";
import UserModal from "../components/UserModal";


const MainListItems = ({ closeDrawer, isSmallScreen }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { handleLogout, user } = useContext(AuthContext);
  const {
    redirectorSelectedIndex,
    setRedirectorSelectedIndex,
    setRedirectorSelectedRoute,
    handleOpenRedirectorSettingsDialogModal
  } = useContext(MenuRedirectorContext);

  const navigate = useNavigate(false);

  const [userModalOpen, setUserModalOpen] = useState(false);
  const [openRegistrationsMenu, setOpenRegistrationsMenu] = useState(false);
  const [openReportsMenu, setOpenReportsMenu] = useState(false);
  const [openLancamentosMenu, setOpenLancamentosMenu] = useState(false);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClickLogout = () => { handleLogout(); };

  const handleOpenUserModal = () => { setUserModalOpen(true); };

  const handleCloseUserModal = () => { setUserModalOpen(false); };

  const redirectToPage = (route) => {
    navigate(route);
    isSmallScreen && closeDrawer();
  };

  const redirectToUsersPage = () => {
    if (isUserAdmin(user)) handleListItemClick(3, "users", false);
    else handleOpenUserModal();
  };

  const handleListItemClick = (index, route, isUsersPage) => {
    setTimeout(() => {
      const pathname = window.location.pathname;
      setRedirectorSelectedIndex(index);
      setRedirectorSelectedRoute(route);
  
      if (index !== 6 && pathname === "/empresaConfig") handleOpenRedirectorSettingsDialogModal(route);
      else if (!isUsersPage && index === 3) window.location.href = route;
      else if (!isUsersPage) redirectToPage(route);
      else redirectToUsersPage();
    }, 1);
  };

  const handleClickRegistrationsMenu = () => { setOpenRegistrationsMenu(previousValue => !previousValue); };

  const handleClickReportsMenu = () => { setOpenReportsMenu(previousValue => !previousValue); };
  const handleClickLancamentosMenu = () => { setOpenLancamentosMenu(previousValue => !previousValue); };



  //  ************
  //  ** Return **
  //  ************
  return (
    <>
      <UserModal
        open={userModalOpen}
        onClose={handleCloseUserModal}
        userId={user && user.id}
        users={[user]}
      />

      <div>
        {/*
          ***---- Registrations ----***
        */}
        <div onClick={handleClickRegistrationsMenu}>
          <ListItemButton button="true" selected={[0, 1, 2, 3].includes(redirectorSelectedIndex)}>
            <ListItemIcon>
              <NoteAltOutlined />
            </ListItemIcon>

            <ListItemText primary={i18n.t("drawerMenu.items.mainItems.registrations")} />

            <IconButton>{openRegistrationsMenu ? <ExpandLess /> : <ExpandMore />}</IconButton>
          </ListItemButton>
        </div>

        {/*
          ***---- Registrations Collapse ----***
        */}
        <Collapse in={openRegistrationsMenu} component="li" timeout="auto" unmountOnExit={true}>
          <List disablePadding>
            <ListItemButton
              button="true"
              selected={redirectorSelectedIndex === 0}
              onClick={() => handleListItemClick(0, "empresas", false)}
              style={{ paddingLeft: "25px" }}
            >
              <ListItemIcon>
                <StoreOutlined />
              </ListItemIcon>

              <ListItemText primary={i18n.t("drawerMenu.items.subItems.companies")} />
            </ListItemButton> 

            <ListItemButton
              button="true"
              selected={redirectorSelectedIndex === 1}
              onClick={() => handleListItemClick(1, "produtos", false)}
              style={{ paddingLeft: "25px" }}
            >
              <ListItemIcon>
                <CategoryOutlined />
              </ListItemIcon>

              <ListItemText primary={i18n.t("drawerMenu.items.subItems.products")} />
            </ListItemButton>

            <ListItemButton
              button="true"
              selected={redirectorSelectedIndex === 2}
              onClick={() => handleListItemClick(2, "clientes", false)}
              style={{ paddingLeft: "25px" }}
            >
              <ListItemIcon>
                <ContactsOutlined />
              </ListItemIcon>

              <ListItemText primary={i18n.t("drawerMenu.items.subItems.customers")} />
            </ListItemButton>

            <ListItemButton
              button="true"
              selected={redirectorSelectedIndex === 3}
              onClick={() => handleListItemClick(3, "users", true)}
              style={{ paddingLeft: "25px" }}
            >
              <ListItemIcon>
                <PersonOutlineOutlined />
              </ListItemIcon>

              <ListItemText primary={i18n.t("drawerMenu.items.subItems.users")} />
            </ListItemButton>
          </List>
        </Collapse>

        {/*
          ***---- Notas ----***
        */}
        <ListItemButton
          button="true"
          onClick={() => handleListItemClick(3, "/?emitirNfce=1", false)}
        >
          <ListItemIcon>
            <NoteAddOutlined />
          </ListItemIcon>

          <ListItemText primary={i18n.t("drawerMenu.items.mainItems.emission")} />
        </ListItemButton>

        {/* 
          ***---- Notas List ----***
        */}
        <ListItemButton
          button="true"
          selected={[4].includes(redirectorSelectedIndex)}
          onClick={() => handleListItemClick(4, "Notas", false)}
        >
          <ListItemIcon>
            <ListAltOutlined />
          </ListItemIcon>

          <ListItemText primary={i18n.t("drawerMenu.items.mainItems.items")} />
        </ListItemButton>

        {/* 
          ***---- Reports ----***
        */}
        <div onClick={handleClickReportsMenu}>
          <ListItemButton button="true" selected={[5, 6].includes(redirectorSelectedIndex)}>
            <ListItemIcon>
              <AssessmentOutlined />
            </ListItemIcon>

            <ListItemText primary={i18n.t("drawerMenu.items.mainItems.reports")} />

            <IconButton>{openReportsMenu ? <ExpandLess /> : <ExpandMore />}</IconButton>
          </ListItemButton>
        </div>

        {/* 
          ***---- Reports Collapse ----***
        */}
        <Collapse in={openReportsMenu} component="li" timeout="auto" unmountOnExit={true}>
          <List disablePadding>
            <ListItemButton
              button="true"
              selected={redirectorSelectedIndex === 5}
              onClick={() => handleListItemClick(5, "relatorioNotas", false)}
              style={{ paddingLeft: "25px" }}
            >
              <ListItemIcon>
                <RequestQuoteOutlined />
              </ListItemIcon>

              <ListItemText primary={i18n.t("drawerMenu.items.subItems.receiptsReport")} />
            </ListItemButton>

            <ListItemButton
              button="true"
              selected={redirectorSelectedIndex === 6}
              onClick={() => handleListItemClick(6, "relatorioProdutos", false)}
              style={{ paddingLeft: "25px" }}
            >
              <ListItemIcon>
                <ContentPasteOutlined />
              </ListItemIcon>

              <ListItemText primary={i18n.t("drawerMenu.items.subItems.productReport")} />
            </ListItemButton>
          </List>
        </Collapse>

        {/* 
          ***---- Lancamentos ----***
        */}
        <div onClick={handleClickLancamentosMenu}>
          <ListItemButton button="true" selected={[5, 6].includes(redirectorSelectedIndex)}>
            <ListItemIcon>
              <AssessmentOutlined />
            </ListItemIcon>

            <ListItemText primary={i18n.t("drawerMenu.items.mainItems.lancamentos")} />

            <IconButton>{openLancamentosMenu ? <ExpandLess /> : <ExpandMore />}</IconButton>
          </ListItemButton>
        </div>

        {/* 
          ***---- Lancamentos Collapse ----***
        */}
        <Collapse in={openLancamentosMenu} component="li" timeout="auto" unmountOnExit={true}>
          <List disablePadding>
            <ListItemButton
              button="true"
              selected={redirectorSelectedIndex === 5}
              onClick={() => handleListItemClick(5, "devolucaoCompra", false)}
              style={{ paddingLeft: "25px" }}
            >
              <ListItemIcon>
                <RequestQuoteOutlined />
              </ListItemIcon>

              <ListItemText primary={i18n.t("drawerMenu.items.subItems.devolucaoDeCompra")} />
            </ListItemButton>
          </List>
        </Collapse>

        {/*
          ***---- Settings ----***
        */}
        <ListItemButton
          button="true"
          selected={[7].includes(redirectorSelectedIndex)}
          onClick={() => handleListItemClick(7, "empresaConfig", false)}
        >
          <ListItemIcon>
            <SettingsOutlined />
          </ListItemIcon>

          <ListItemText primary={i18n.t("drawerMenu.items.mainItems.settings")} />
        </ListItemButton>

        {/*
          ***---- Logout ----***
        */}
        <ListItemButton button="true" onClick={handleClickLogout}>
          <ListItemIcon>
            <LogoutOutlined />
          </ListItemIcon>
          <ListItemText primary={i18n.t("drawerMenu.items.mainItems.logout")} />
        </ListItemButton>
      </div>
    </>
  );
};

export default MainListItems;
