import React, { useEffect, useState } from "react";
import { TextField, useMediaQuery } from "@mui/material";
import { Field, useField, useFormikContext } from "formik";

const FlexTextField = ({
  label,
  name,
  width,
  inputMode = "text",
  autoFocus = false,
  error,
  helperText,
  disabled = false,
  onChangeEvent = null,
  onChangeMaxLength = 0
}) => {
  const isSmallScreen = useMediaQuery("(max-width:825px)");
  const { setFieldValue } = useFormikContext();
  
  const [ field ] = useField(name);
  const [ localValue, setLocalValue ] = useState(field.value || '')

  useEffect(() =>{
    setLocalValue(field.value)
  }, [field.value]);

  return (
    <Field
      as={TextField}
      label={label}
      name={name}
      variant="outlined"
      margin="dense"
      fullWidth={isSmallScreen}


      autoFocus={autoFocus}
      style={{ width: isSmallScreen? "100%" : width }}
      inputProps={{ inputMode: inputMode }}
      error={error}
      helperText={helperText}
      disabled={disabled}
      value={localValue}
      onChange={(event) => {
        let newValue = event.target.value;

        if (onChangeEvent) {
          newValue = onChangeEvent(event, onChangeMaxLength)
            ? event.target.value
            : localValue;
        }
        setFieldValue(name, newValue);
        setLocalValue(newValue);
      }}
    />
  );
};

export default FlexTextField;
