import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { Formik, Form, Field } from "formik";
import { green } from '@mui/material/colors';
import { Visibility, VisibilityOff } from "@mui/icons-material"
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	Select,
	InputLabel,
	MenuItem,
	FormControl,
	TextField,
	InputAdornment,
	IconButton,
	useMediaQuery	 
} from '@mui/material';

import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import MainHeaderFX from "../MainHeaderFx";
import toastError from "../../errors/toastError";
import DialogModal from "../DialogModal";
import { flattenFormikValidationErrors } from "../../utils/formatacao/flattenFormikValidationErrors";
import { recuperarSenha } from "../../utils/funcoes/api/recuperarSenha";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(0),
		},
		floatingButton: {
			'&:hover': {
				transform: 'translateY(-5px)',
			},
		}
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	floatingButton: {
		transition: 'transform 0.3s',
	
		'&:hover': {
			transform: 'translateY(-5px)',
		},
	},
	coresAplicacao: {       
	   heigth: "25px",
	   width: "25px",
	   borderRadius: "2px",
	   margin: "0 10px 10px 0",
	   display: "flex",
	   flexDirection: "row",	   
	}
}));

const UserModal = ({ open, onClose, userId, users }) => {
	// 	***************
	// 	** Variables **
	// 	***************
	const classes = useStyles();

	const initialState = {
		name: "",
		email: "",
		password: "",
		profile: "user",
		tenantId: "",
		principal: false
	};

	const UserSchema = Yup.object().shape({
		name: Yup.string()
			.min(2, "Muito curto!")
			.max(50, "Muito longo!")
			.required("Obrigatório"),
			password:(userId ?  Yup.string().min(5, "Muito curto!").max(50, "Muito longo!") : Yup.string().min(5, "Muito curto!").max(50, "Muito longo!").required("Obrigatório")),
		email: Yup.string().email("e-mail inválido").required("Obrigatório"),
	});

	const { user: loggedInUser } = useContext(AuthContext);
	const isSmallScreen = useMediaQuery('(max-width:825px)');

	const [user, setUser] = useState(initialState);	
	const [showPassword, setShowPassword] = useState(true);	
	const [showPasswordInitial, setShowPasswordInitial] = useState(false);
	const [dialogModal, setDialogModal] = useState({open: false, title: "", description: "", buttons: [], result: ""});

	

	// 	*****************
	// 	** Use Effects **
	// 	*****************
	useEffect(() => {
		const fetchUser = async () => {
			if (!userId) return;
			try {
				const { data } = await api.get(`/v1/users/${userId}`);
				setUser(prevState => {
					return { ...prevState, ...data };
				});				
			} catch (exception) {
				toastError(exception);
			}
		};

		fetchUser();
	}, [userId, open]);



	// 	***************
	// 	** Functions **
	// 	***************
	const handleClose = () => {
		setShowPasswordInitial(false);
		setShowPassword(true);
		onClose();
		setUser(initialState);
	};	

	const handleSaveUser = async (values) => {
		const userData = { ...values};
		
		if (loggedInUser.id !== userData.id && loggedInUser.profile !== "admin") {
			toast.info(i18n.t("toasts.exceptions.unauthorizedUserCreationAndUpdate"));
			handleClose();
			return;
		}

		try {
			if (userId) {				
				const { data } = await api.put(`/v1/users/${userId}`, userData);
				let userIndex = users.findIndex((u) => u.id === userId);
				users[userIndex] = data;
			}
			else {		
				const { data } = await api.post("/v1/users", userData);
				users.push(data);
			}

			toast.success(i18n.t("toasts.saved"));
		} catch (exception) {
			toastError(exception);
		}

		handleClose();
	};

	const handleChangePassword = async (values) => {
		try {
			await recuperarSenha(values);
			setDialogModal({...dialogModal, open: true, title: "Troca de senha", description: `Você receberá um email em ${values.email} com instruções para trocar a sua senha. Pode demorar alguns minutos, verifique seu spam.`, buttons: ["OK"]});
		}
		catch (exception) {
			toastError(exception)
		}
	}

	const handleCloseDialogModal = (result) => {
        setDialogModal({open: false});
        handleClose();
    };

	const onFocus = () => {
		if (!showPasswordInitial)
		{
			setShowPasswordInitial(true);
			document.getElementById("btnPasswordIcon").click();
		}
	}

	// 	************
	// 	** Return **
	// 	************
	return (
		<>
			<DialogModal
				title={dialogModal.title}
				open={dialogModal.open}
				onClose={handleCloseDialogModal}
				description={dialogModal.description}
				buttons={dialogModal.buttons}
			/>

			<div className={classes.root}>
				<Dialog
					open={open}
					onClose={handleClose}
					maxWidth="xs"
					fullScreen={isSmallScreen}
					scroll="paper"
				>
					<Formik
						initialValues={user}
						enableReinitialize={true}
						validationSchema={UserSchema}
						onSubmit={(values, actions) => {
							setTimeout(() => {
								handleSaveUser(values);
								actions.setSubmitting(false);
							}, 400);
						}}
						>
						{({ touched, errors, isSubmitting , values, validateForm, setFieldTouched}) => (
							<Form>
								{isSmallScreen ? (
									<MainHeaderFX
										title={i18n.t("user.title")}
										leftContainerType={"modal"}
										rightContainerType={"modal"}
										handleCloseModal={handleClose}
										handleSaveModal={async () => {
											const validationErrors = await validateForm();
											const flattenedValidationErrors = flattenFormikValidationErrors(validationErrors);
											const fieldsWithErrors = Object.keys(flattenedValidationErrors);
											
											if (fieldsWithErrors.length === 0) {
												handleSaveUser(values);
											}
											else {
											  fieldsWithErrors.forEach(field => setFieldTouched(field, true, false));
											  toast.info(i18n.t("produto.toasts.requiredFieldsAreBlank"));
											}
										  }}
								/>
								) : (
									<DialogTitle >
										{i18n.t("user.title")}
									</DialogTitle>
								)}	

								<DialogContent dividers>
									<div className={classes.multFieldLine}>						

										<Field
											as={TextField}
											label={i18n.t("user.field.name")}
											autoFocus
											name="name"
											error={touched.name && Boolean(errors.name)}
											helperText={touched.name && errors.name ? "" : undefined}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
										{!values.principal && <Field	
											as={TextField}
											name="password"
											variant="outlined"
											margin="dense"
											label={i18n.t("user.field.password")}
											error={touched.password && Boolean(errors.password)}
											helperText={touched.password && errors.password ? "" : undefined}
											type={showPassword ? 'text' : 'password'}
											onFocus={onFocus}
											InputProps={{
											endAdornment: (
												<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													id="btnPasswordIcon"
													onClick={() => setShowPassword((e) => !e)}
												>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
												</InputAdornment>
											)
											}}
											fullWidth
										/>}
									</div>
									<div className={classes.multFieldLine}>
										<Field
											as={TextField}
											label={i18n.t("user.field.email")}
											name="email"
											disabled={values.principal}
											error={touched.email && Boolean(errors.email)}
											helperText={touched.email && errors.email ? "" : undefined}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
										<FormControl
											className={classes.formControl}
											variant="outlined"
											margin="dense"
											fullWidth
										>
											<Can
												role={loggedInUser.profile}
												perform="user-modal:editProfile"
												yes={() => (
													<>
														<InputLabel id="profile-selection-input-label">
															{i18n.t("user.field.profile")}
														</InputLabel>

														<Field
															as={Select}
															label={i18n.t("user.field.profile")}
															name="profile"
															disabled={values.principal}
															labelId="profile-selection-label"
															id="profile-selection"
															required
															>
															<MenuItem value="admin">{i18n.t("user.field.admin")}</MenuItem>
															<MenuItem value="user">{i18n.t("user.field.user")}</MenuItem>
														</Field>
													</>
												)}
											/>
										</FormControl>
									</div>							
									
									</DialogContent>	
									
									{!isSmallScreen && (
										<DialogActions>
											{values.principal &&  <Button
												onClick={() => handleChangePassword(values)}
												color="inherit"
												disabled={isSubmitting}
												variant="outlined"
												className={classes.btnWrapper}
												
											>
												{"TROCAR SENHA"}
											</Button>}
											<Button
												onClick={handleClose}
												color="inherit"
												disabled={isSubmitting}
												variant="outlined"
												className={classes.floatingButton}
											>
												{"CANCELAR"}
											</Button>										
											<Button
												type="submit"
												color="primary"
												disabled={isSubmitting}
												variant="contained"
												className={`${classes.btnWrapper} ${classes.floatingButton}`}
											>
												{userId
													? `${"SALVAR"}`
													: `${"ADICIONAR"}`}
												{isSubmitting && (
													<CircularProgress
														size={24}
														className={classes.buttonProgress}
													/>
												)}
											</Button>		 
																	
										</DialogActions>
									)}

									{isSmallScreen && (
										<DialogActions>
											{values.principal &&  <Button 
												style={{
													width: "100%"
												}}
												onClick={() => handleChangePassword(values)}
												color="inherit"
												disabled={isSubmitting}
												variant="outlined"
												className={classes.btnWrapper}
												
											>
												{"TROCAR SENHA"}
											</Button>}
										</DialogActions>
									)}
							</Form>
						)}
					</Formik>
				</Dialog>
			</div>
		</>		
	);
};

export default UserModal;