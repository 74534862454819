import React, { useContext, useState } from "react";

import "./style.css"
import Typography from '@mui/material/Typography';
import { AuthContext } from "../../context/Auth/AuthContext";
import { Button, Container,  IconButton, InputAdornment, Link, TextField, useMediaQuery } from "@mui/material";

/* Imagens e icones*/
import iconFX from '../../assets/fx_sistemas_logo.png';
import logoIMG from "../../assets/notaCerta.png"
import { AccountCircle, LockOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import { recuperarSenha } from "../../utils/funcoes/api/recuperarSenha";
import DialogModal from "../../components/DialogModal";
import InputDialogModal from "../../components/InputDialogModal";

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright "}
      <Link color="inherit" href="https://www.fxsistemas.com.br" target="_blank">
        FX Sistemas
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const Login = () => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { handleLogin } = useContext(AuthContext)
  const isSmallScreen = useMediaQuery("(max-width:825px)");
  const [user, setUser] = useState({ email: "", password: "" })
  const [showPassword, setShowPassword] = useState(false)
  const [dialogModal, setDialogModal] = useState({open: false, title: "", description: "", buttons: [], result: ""});
  const [inputDialogModal, setInputDialogModal] = useState({open: false, keyword: "", title: "", description: "", result: "", answer: ""});

  //  ***************
  //  ** Functions **
  //  ***************

  function isValidEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
}

  const handleChangeInput = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleLogin(user);
  };

  const handleMudarSenha = () => {
		setInputDialogModal({...inputDialogModal, keyword: "mudar-senha", open: true, title: "Recuperação de senha", description: "Confirme seu e-mail", answer: user.email});
	}

  const handleCloseInputDialogModal = (result, answer) => {
		const keyword = inputDialogModal.keyword;
		setInputDialogModal({...inputDialogModal, keyword: "", open: false, result: result, answer: ""});
		
		if (result === "Confirmar" ) {
			switch (keyword) {
				case "mudar-senha": {
					if (!isValidEmail(answer))
						setDialogModal({...dialogModal, keyword: "info", open: true, title: "Aviso", description: "Email inválido!", buttons: ["OK"]});		
					else
						enviarRecuperacao(answer);
					break;
				}
			}	
		}
	};

  const handleCloseDialogModal = (result) => {
		setDialogModal({...dialogModal, keyword: "", open: false, result: ""});
	};
  const enviarRecuperacao = async (answer) => {
		try {
			await recuperarSenha({email: answer});
			setDialogModal({...dialogModal, open: true, title: "Troca de senha", description: `Você receberá um email em ${answer} com instruções para trocar a sua senha. Pode demorar alguns minutos, verifique seu spam.`, buttons: ["OK"]});
		}
		catch (exception) {
			toastError(exception)
		}
	} 

  //  ************
  //  ** Return **
  //  ************
  return (    
    <>
    <DialogModal
				title={dialogModal.title}
				open={dialogModal.open}
				onClose={handleCloseDialogModal}
				description={dialogModal.description}
				buttons={dialogModal.buttons}
			/>

      <InputDialogModal
				title={inputDialogModal.title}
				open={inputDialogModal.open}
				onClose={handleCloseInputDialogModal}  
				description={inputDialogModal.description}  
        resposta={inputDialogModal.answer}      
				>				
			</InputDialogModal>

    <div className="login-backGround">
      <Container className={isSmallScreen ? "mainContainer" : "mainDesktop2"}>   
        <Container className={isSmallScreen ? "logoSmall" : "logo"}>
          <img alt="Nota Certa" id={isSmallScreen ? "logoTipoSmall" : "logoTipoDesktop"} src={logoIMG}/>
        </Container>

        <Container className={isSmallScreen ? "login" : "loginDesktop"}>
          <Container>
            <Typography id="tituloLogin">{i18n.t("login.login")}</Typography>
            <Typography id="tituloLogin2">{i18n.t("login.message")}</Typography>
          </Container>     

          <form className="form" noValidate onSubmit={handleSubmit}>
            <center>          
              <TextField
                variant="outlined"
                margin="normal"
                required
                style={{width:"80%"}}
                id="email"
                label={i18n.t("login.form.email")}
                name="email"
                value={user.email}
                onChange={handleChangeInput}
                autoComplete="email"
                autoFocus
                inputProps= {{ startadorment: (<InputAdornment position="start"><AccountCircle/></InputAdornment>) }}
              />

              <TextField
                variant="outlined"
                margin="normal"
                required
                style={{width: "80%"}}
                name="password"
                label={i18n.t("login.form.password")}
                id="password"
                value={user.password}
                onChange={handleChangeInput}
                autoComplete="current-password"            
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  startAdornment: (<InputAdornment position="start"><LockOutlined /></InputAdornment>),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword((event) => !event)}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />

              <br/> 
              <div style={{width: "80%", textAlign: "right"}}>
                <a onClick={() => handleMudarSenha()} style={{fontSize: "12px", cursor: "pointer", textDecoration: "underline"}}>{i18n.t("login.form.esqueciSenha")}</a>
              </div>

              <Button 
                type="submit floatingButton floatingButton:hover"
                style={{width: "80%", marginTop: "10px"}}
                variant="contained"
                color="primary"              
              >
                {i18n.t("login.buttons.submit")}
              </Button>  
            
              <a href="https://www.fxsistemas.com.br" target="_blank" rel="noreferrer">
                <img id="logoFx" src={iconFX} alt="FX Sistemas Logo" />  
              </a>

              { <Copyright /> }
            </center>                    
          </form>
        </Container>      
      </Container>      
    </div>
    </>
  );
};

export default Login;