import React, { useEffect, useReducer, useState } from "react";
import {
  Container,
  Paper,
  SpeedDial,
  SpeedDialIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useMediaQuery,
} from "@mui/material";

import naoEncontrado from "../../assets/naoEncontrado.svg"

import api from "../../services/api.js";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError.js";
import ProdutoModal from "../../components/ProdutoModal";
import MainHeaderFx from "../../components/MainHeaderFx";
import { Configuracao } from "../../configuracao/index.js";
import MainContainer from "../../components/MainContainer/index.js";
import TableRowSkeleton from "../../components/TableRowSkeleton/index.js";

import "./style.css";

const SortReducer = (state) => {
  state.sort((produtoA, produtoB) => {
    return produtoA.nome.localeCompare(produtoB.nome);
  });

  return [...state];
};

const reducer = (state, action) => {
  if (action.type === "LOAD_PRODUTO") {
    const produtos = action.payload;
    const newProdutos = [];

    produtos.forEach((produto) => {
      const produtoIndex = state.findIndex((p) => p.id === produto.id);
      if (produtoIndex !== -1) {
        state[produtoIndex] = produto;
      } else {
        newProdutos.push(produto);
      }
    });

    const newState = SortReducer([...state, ...newProdutos]);

    return [...newState];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Produto = () => {
  //  ***************
  //  ** Variáveis **
  //  ***************
  const [produtos, dispatch] = useReducer(reducer, []);
  const isSmallScreen = useMediaQuery("(max-width:825px)");

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isScroll, setIsScroll] = useState(false);
  const [search, setSearch] = useState("");

  const [produtoModalOpen, setProdutoModalOpen] = useState(false);
  const [selectedProduto, setSelectedProduto] = useState(null);

  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPage(1);
  }, [search]);

  useEffect(() => {
    setLoading(true);

    const delayDebounceFn = setTimeout(() => {
      const fetchProdutos = async () => {
        try {
          const { data } = await api.get("v1/produtos/", {
            params: { page, search, size: 25, order: ["nome"] },
          });
          setIsScroll(true);
          dispatch({ type: "LOAD_PRODUTO", payload: data.produtos });
          setHasMore(data.hasMore);
          setTotalCount(data.count);
          setLoading(false);
        } catch (error) {
          toastError(error);
        }
      };
      fetchProdutos();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [page, search]);

  //  ***************
  //  ** Functions **
  //  ***************
  const handleOpenModal = () => {
    setProdutoModalOpen(true);
  };

  const handleEditModal = (produto) => {
    setSelectedProduto(produto);
    setProdutoModalOpen(true);
  };

  const handleCloseModal = () => {
    setProdutoModalOpen(false);
    setSelectedProduto(null);
  };

  const loadMore = () => {
    if (isScroll) {
      setPage((previousState) => previousState + 1);
      setIsScroll(false);
    }
  };

  const handleScroll = (event) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if (scrollHeight - (scrollTop + 1) < clientHeight) {
      loadMore();
    }
  };

  //  ************
  //  ** Return **
  //  ************
  return (
    <div class="pageRoot">
      <ProdutoModal
        open={produtoModalOpen}
        onClose={handleCloseModal}
        sortFunction={SortReducer}
        produtoId={selectedProduto && selectedProduto.id}
        produtos={produtos}
        setTotalCount={setTotalCount} 
      />

      <MainHeaderFx
        title={i18n.t("produto.pluralTitle")}
        leftContainerType={"page"}
        rightContainerType={"search"}
        search={search}
        setSearch={setSearch}
        titleButton={i18n.t("button.add")}
        backParam={"/"}
        handleModal={handleOpenModal}
      />

      <MainContainer>
        <Paper class="scroll" onScroll={handleScroll}>
          <TableContainer>
            <Table>
              <TableBody>
                {produtos.map((produto, index) => (
                  <TableRow key={index} onClick={() => handleEditModal(produto)}>
                    <TableCell>{produto.nome}</TableCell>
                    <TableCell>{produto.status}</TableCell>
                    <TableCell>{parseFloat(produto.preco).toFixed(2).replace(".", ",")}</TableCell>
                  </TableRow>
                ))}
                {loading && <TableRowSkeleton columns={3} />}
              </TableBody>
            </Table>
          </TableContainer>

          {totalCount === 0 && !loading && (
            <Container class="record">
              <img alt="consulta" 
                id="logoTipo"                         
                style={{ width: "70%", height: "30%" }} 
                src={naoEncontrado}
              /> 
              <br/> 
              <span style={{fontSize: "1em", fontWeight:"bold", opacity: 0.85}} >{Configuracao.LISTAGEM_VAZIA} </span>
            </Container>
          )}

          {isSmallScreen && (
            <SpeedDial class="iconAdd" 
              ariaLabel="Add Product"
              onClick={handleOpenModal}
              open={false}
              icon={<SpeedDialIcon />}
            />            
          )}
        </Paper>
      </MainContainer>
    </div>
  );
};

export default Produto;
