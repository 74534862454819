export const limparJson = (json, tag) => {
    if (typeof json === "object" && json !== null) {
        for (const key in json) {
          if (key === tag) {
            return json[key]; 
          }
          json[key] = limparJson(json[key], tag);
        }
      }
      return json;
}