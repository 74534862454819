export const gerarCfoDevolucao = (cfopAnterior, operacao, idDest) => {

  /*
    Operação
    01-Revenda de mercadoria. (Comércio)
    02-Revenda de mercadoria efetuada fora do estabelecimento. (Comércio)
    03-Venda de produção própria (Industria ou equiparada)
    04-Venda de produção própria  efetuada fora do estabelecimento. (Indústria ou Equiparada)
  */

  let cfop = "";

  const operacaoRevenda = ["01", "02"];
  const operacaoProducao = ["03", "04"];

  switch (idDest) {
    
    case "1": { 

      /* 5000 */

      const cfopST = ["5401","5402","5403","5405"];

      if (cfopST.includes(cfopAnterior)) {
        cfop = operacaoRevenda.includes(operacao) ? "5411" : operacaoProducao.includes(operacao) ? "5410" : "";
      } else {
        cfop = cfopAnterior === "5551" ? "5553" : operacaoRevenda.includes(operacao) ? "5202" : operacaoProducao.includes(operacao) ? "5201" : "";
      }

      break;
    }

    case "2": {

      /* 6000 */

      const cfopSTInterestadual = ["6401","6402","6403","6405"];

      if (cfopSTInterestadual.includes(cfopAnterior)) {
        cfop = operacaoRevenda.includes(operacao) ? "6411" : operacaoProducao.includes(operacao) ? "6410" : "";
      } else {
        cfop = cfopAnterior === "6551" ? "6553" : operacaoRevenda.includes(operacao) ? "6202" : operacaoProducao.includes(operacao) ? "6201" : "";
      }

      break;
    }
  } 

  return cfop;
}


