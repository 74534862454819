export const removerFieldJson = (obj, fieldToRemove) => {
    if (Array.isArray(obj)) {
        return obj.map(item => removerFieldJson(item, fieldToRemove));
    } else if (obj && typeof obj === 'object') {
        const result = {};
        for (const key in obj) {
            if (key !== fieldToRemove) {
                result[key] = removerFieldJson(obj[key], fieldToRemove);
            }
        }
        return result;
    }
    return obj; 
}