import React, { useEffect, useState } from "react";

import { Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Input
} from "@mui/material";  

import "./style.css";

const InputDialogModal = ({ title, description, open, onClose, widthContent = "250px", resposta = "" }) => {

	const [inputValue, setInputValue] = useState("");

	useEffect(() => {
		const preencherResposta = async () => {
			if (!open) 
				return;
			setInputValue(resposta);
		};

		preencherResposta();
	}, [open]);

	return (
		<Dialog
			open={open}
			onClose={() => {onClose("", "")}}
		>
			<DialogTitle >{title}</DialogTitle>
			<DialogContent dividers>
				{description}
				<br/>
				<Input style={{"width": widthContent}}
				id="input.dialog.modal"
				value={inputValue} 
				onChange={(e) => setInputValue(e.target.value)} 
				/>
			</DialogContent>
			<DialogActions>
			
			<Button onClick={() => {const answer = document.getElementById("input.dialog.modal").value; onClose("Confirmar", answer)}}>{"Confirmar"}</Button>
			<Button onClick={() => onClose("Sair", "")}>{"Sair"}</Button>
			</DialogActions>
		</Dialog>
		
	);
};

export default InputDialogModal;
