import { limparJson } from './limparJson';

export const xmlParaJson = (xml) => {
  const DOMParser = require('xmldom').DOMParser;
  const doc = new DOMParser().parseFromString(xml, "text/xml");

    function traverse(node) {
        const obj = {};
        if (node.nodeType === 1) {
            if (node.attributes.length > 0) {
                for (let j = 0; j < node.attributes.length; j++) {
                    const attribute = node.attributes.item(j);
                    obj[attribute.nodeName] = attribute.nodeValue;
                }
            }
        } else if (node.nodeType === 3) {
            obj[node.nodeName] = node.nodeValue;
        }
        if (node.hasChildNodes()) {
            for (let i = 0; i < node.childNodes.length; i++) {
                const item = node.childNodes.item(i);
                const nodeName = item.nodeName;
                if (typeof obj[nodeName] === "undefined") {
                    obj[nodeName] = traverse(item);
                } else {
                    if (typeof obj[nodeName].push === "undefined") {
                        const old = obj[nodeName];
                        obj[nodeName] = [];
                        obj[nodeName].push(old);
                    }
                    obj[nodeName].push(traverse(item));
                }
            }
        }
        return obj;
    }

    let jsonResult = traverse(doc);

    for (let index = 0; index < 2; index++) {
        jsonResult = limparJson(jsonResult, "#text")      
    }

    return jsonResult;
}